<template>
  <b-badge
    v-b-tooltip.hover.top="tagDesc"
    pill
    :style="
      `background-color: ${rgba}; background-opacity: 0.12; color: ${color};`
    "
  >{{ tagName }}</b-badge>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      required: true,
    },
    tagDesc: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    rgba() {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.color)
      return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.12)` : null
    },
  },
}
</script>

<style>

</style>
